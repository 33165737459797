import React from 'react';
import { Col, Container, ResponsiveEmbed, Row } from 'react-bootstrap';
import { ActionButton, Disqus, Footer, Layout, Navbar, PageHeader, Sponsor } from '@components';
import { site, videoFallback } from '@variables';
import SummerCampFlyer from '@assets/documents/early-IT/EarlyIT-SummerCampFlyer23.pdf';
import EarlyITOnePager from '@assets/documents/early-IT/EarlyIT-ProgramBrochure23.pdf';

const EarlyITPage = () => <Layout className="about-page">
  <Navbar />
  <PageHeader title="SoIT - Early IT Program" />

  <Container style={{ marginBottom: `1rem`, marginTop: `3rem` }}>
    <Row className="justify-content-center">
      <Col md={8}>
        <ResponsiveEmbed aspectRatio="16by9" className="mr-md-2 mb-3">
          <iframe
            title="Discover the Early IT Program"
            src={
              videoFallback ?
                `` :
                `https://player.vimeo.com/video/533216875`
            }
            className="embed-responsive-item"
            allow="autoplay; fullscreen"
            allowFullScreen />
        </ResponsiveEmbed>
      </Col>
    </Row>
    <Row className="justify-content-center mb-3">
      <a href={EarlyITOnePager} download="EarlyIT-ProgramBrochure23.pdf">
        <ActionButton>Early IT Program Brochure</ActionButton>
      </a>
      <a href={SummerCampFlyer} download="EarlyIT-SummerCampFlyer23.pdf">
        <ActionButton>Early IT Summer Camp</ActionButton>
      </a>
      <a href="https://cech.uc.edu/schools/it/early-it/early-it-faq.html" target="_blank" rel="noopener noreferrer">
        <ActionButton>FAQS</ActionButton>
      </a>
    </Row>
    <Disqus
      identifier="about-earlyit-2022"
      title="SoIT - Early IT Program"
      pageLocation={`${site.url}/about/early-it`}
    />
  </Container>

  <Sponsor />
  <Footer />
</Layout>;

export default EarlyITPage;
